@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    pointer-events: none;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 400;
  pointer-events: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Display a centered modal
  &.center {
    justify-content: center;
  }

  // Display a modal aligned to the bottom
  &.bottom {
    justify-content: flex-end;
    overflow: hidden;
    .inner {
      margin-bottom: 0px;
      padding-bottom: calc(max(env(safe-area-inset-bottom), 10px));
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      @media (orientation: portrait) {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
  animation: fadeIn 0.1s linear;
}

//TODO: Maybe use card component instead of something custom
.inner {
  padding: 0.5rem;
  animation: fadeIn 0.1s linear;
  position: relative;
  width: min(calc(100% - 20px), max(70vw, 500px));
  margin: max(env(safe-area-inset-top), 10px) max(env(safe-area-inset-right), 10px)
    calc(max(env(safe-area-inset-bottom), 10px) + 15px) max(env(safe-area-inset-left), 10px);
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  opacity: 1;
  //animation: slideInUp 0.2s linear;
  max-height: calc(100vh - 50px);
  overflow: auto;
  @media (orientation: landscape) {
    width: min(800px, calc(100% - 20px));
  }

  display: grid;
  grid-template-columns: [column-start] auto 1fr [column-end];
  grid-template-areas: "header control";
  align-items: center;
  > *:nth-child(1n + 1) {
    grid-column-start: column-start;
    grid-column-end: column-end;
  }
  .meta {
    grid-area: header;
  }
  .closeButton {
    grid-area: control !important;
  }
  &.control {
    > .meta + :not(.closeButton),
    .closeButton + * {
      grid-area: control;
    }
  }
  &.fitContent {
    max-height: none;
    height: auto;
    width: auto;
    max-width: none;
  }
}

.closeButton {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 48px;
  height: 48px;
  border: 0;
  background-color: transparent;
}

.meta {
  padding: 1rem 0.5rem;
}
